<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :wrapperClosable="false"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{
            saveType === "add"
              ? "新增App"
              : saveType === "show"
              ? "查看App"
              : "修改App"
          }}</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            round
            @click="handleSave"
            :loading="saving"
            :disabled="disabled"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          label-width="150px"
          :disabled="disabled"
        >
          <cond-card>
            <el-form-item label="触达通道" prop="AppChannelType">
              <el-select
                v-model="formData.AppChannelType"
                placeholder="请选择App推送的渠道"
                style="width: 440px"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="channelLabelMap.Name" prop="Name">
              <el-input
                v-model="formData.Name"
                placeholder="请输入账号名称"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item :label="channelLabelMap.AppID" prop="AppID">
              <el-input
                v-model="formData.AppID"
                placeholder="请输入AppKey"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item label="MasterSecret" prop="AppSecret">
              <el-input
                v-model="formData.AppSecret"
                placeholder="请输入MasterSecret"
                type="password"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <template v-if="formData.AppChannelType === '极光推送'">
              <el-form-item label="厂商通道 uri_activity">
                <el-input
                  v-model="formData.uri_activity"
                  placeholder="请输入uri_activity"
                  style="width: 440px"
                ></el-input>
              </el-form-item>
              <el-form-item label="厂商通道 uri_action">
                <el-input
                  v-model="formData.uri_action"
                  placeholder="请输入uri_action"
                  style="width: 440px"
                ></el-input>
              </el-form-item>
              <el-form-item label="mutable_content">
                <el-select
                  v-model="formData.mutable_content"
                  placeholder="请选择mutable_content"
                  style="width: 440px"
                >
                  <el-option :key="true" :value="true" label="true"
                    >true</el-option
                  >
                  <el-option :key="false" :value="false" label="false"
                    >false</el-option
                  >
                </el-select>
              </el-form-item>
              <el-form-item label="安卓推送Intent模板">
                <el-input
                  v-model="formData.intent"
                  placeholder="请输入安卓推送Intent模板"
                  style="width: 440px"
                ></el-input>
              </el-form-item>
            </template>
            <el-form-item label="推送ID" prop="audience_id_type">
              <el-select
                v-model="formData.audience_id_type"
                placeholder="请选择推送的ID信息"
                style="width: 440px"
              >
                <el-option
                  v-for="item in audienceIdTypeList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否启用" prop="IsActive">
              <el-switch v-model="formData.IsActive"> </el-switch>
            </el-form-item>
            <el-form-item label="自定义跳转链接">
              <Keys ref="keysRef" :defaultValue="formData.extras_keys" />
            </el-form-item>
            <!-- <div class="keysBox">
              <Keys ref="keysRef" :defaultValue="formData.extras_keys" />
            </div> -->
          </cond-card>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  apiAddChannel,
  apiUpdateChannel,
  apiGetChannelDetail,
} from "@/api/channel";
import condCard from "@/components/cond-card";
import Keys from "./keys.vue";

export default {
  components: {
    condCard,
    Keys,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      saving: false,
      direction: "rtl",
      formData: {
        Name: "",
        Type: "APP",
        AppID: "",
        AppSecret: "",
        IsActive: true,
        AppChannelType: "",
        extras_keys: "",
        audience_id_type: "",
        uri_activity: "",
        uri_action: "",
        mutable_content: "",
        intent: "",
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入App名称",
          trigger: "blur",
        },
        AppID: {
          required: true,
          message: "请输入APPID",
          trigger: "blur",
        },
        AppSecret: {
          required: true,
          message: "请输入MasterSecret",
          trigger: "blur",
        },
        AppChannelType: {
          required: true,
          message: "请选择App推送的渠道",
          change: "blur",
        },
        audience_id_type: {
          required: true,
          message: "请选择推送的ID信息",
          change: "blur",
        },
      },
      disabled: false,
    };
  },
  computed: {
    ...mapGetters({
      channelFields: "common/channelFields",
      channelLabelMap: "common/channelLabelMap",
    }),
    typeList() {
      return ["极光推送"];
    },
    audienceIdTypeList() {
      return ["alias", "registration_id"];
    },
  },
  watch: {
    exist(newVal) {
      if (newVal === true) {
        this.getChannelDetail();
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      this.saving = true;
      const param = {
        ...this.formData,
      };
      if (this.$refs.keysRef) {
        param.extras_keys = this.$refs.keysRef.getData();
      } else {
        param.extras_keys = {};
      }

      param.IsActive = param.IsActive ? 1 : 0;
      let prom;
      if (this.saveType === "add") {
        prom = apiAddChannel(param);
      } else {
        prom = apiUpdateChannel(this.detail._id, param);
      }

      prom
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
          console.log(res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    resetForm() {
      this.formData = {
        Name: "",
        Type: "APP",
        AppID: "",
        AppSecret: "",
        IsActive: true,
        AppChannelType: "",
        extras_keys: "",
        audience_id_type: "",
        uri_activity: "",
        uri_action: "",
        mutable_content: "",
        intent: "",
      };
    },
    getChannelDetail() {
      if (this.saveType === "edit" || this.saveType === "show") {
        if (this.saveType === "show") {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
        apiGetChannelDetail(this.detail._id).then((res) => {
          Object.assign(this.formData, {
            Name: res.Name,
            AppID: res.AppID,
            AppSecret: res.AppSecret,
            IsActive: res.IsActive == 1 ? true : false,
            audience_id_type: res.audience_id_type,
            AppChannelType: res.AppChannelType,
            extras_keys: res.extras_keys,
            uri_activity: res.uri_activity,
            uri_action: res.uri_action,
            mutable_content: res.mutable_content,
            intent: res.intent,
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/ .el-drawer {
    width: 72% !important;
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }

  .cond-combination {
    .title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }
    .content {
      padding: 20px;
      padding-bottom: 6px;
      background: #f6f7f8;
      border-radius: 6px;
    }
  }
  .plan-types {
    overflow: hidden;
    margin-bottom: -16px;
  }

  // .keysBox {
  //   margin-left: 40px;
  // }
}
</style>
