<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :wrapperClosable="false"
      :show-close="false"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{
            saveType === "add"
              ? "新增短信平台"
              : saveType === "show"
              ? "查看短信平台"
              : "修改短信平台"
          }}</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            round
            @click="handleSave"
            :loading="saving"
            :disabled="disabled"
            >保存
          </el-button>
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          label-width="100px"
          :disabled="disabled"
        >
          <cond-card>
            <el-form-item label="短信平台" prop="SMSChannelType">
              <el-select
                v-model="formData.SMSChannelType"
                placeholder="请选择短信平台"
                class="itemWidth"
                @change="selectChange"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账号名称" prop="Name">
              <el-input
                v-model="formData.Name"
                placeholder="请输入账号名称"
                class="itemWidth"
              ></el-input>
            </el-form-item>
            <!-- 创蓝云智 大汉 永达自定义 -->
            <template
              v-if="
                formData.SMSChannelType === '创蓝云智' ||
                formData.SMSChannelType === '大汉' ||
                formData.SMSChannelType === '永达自定义'
              "
            >
              <el-form-item label="请求地址" prop="ServiceURL">
                <el-input
                  v-model="formData.ServiceURL"
                  placeholder="请输入请求地址"
                  class="itemWidth"
                ></el-input>
              </el-form-item>
              <el-form-item label="发送账号" prop="AppID">
                <el-input
                  v-model="formData.AppID"
                  placeholder="请输入发送账号"
                  class="itemWidth"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="AppSecret">
                <el-input
                  v-model="formData.AppSecret"
                  placeholder="请输入密码"
                  type="password"
                  class="itemWidth"
                ></el-input>
              </el-form-item>
            </template>
            <!-- 梦网云 -->
            <template v-if="formData.SMSChannelType === '梦网云'">
              <el-form-item label="鉴权规则" prop="SMSAuthenticationType">
                <el-select
                  v-model="formData.SMSAuthenticationType"
                  placeholder="请选择鉴权规则"
                  class="itemWidth"
                >
                  <el-option
                    v-for="item in SMSAuthenticationTypeList"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="请求地址" prop="ServiceURL">
                <el-input
                  v-model="formData.ServiceURL"
                  placeholder="请输入请求地址"
                  class="itemWidth"
                ></el-input>
              </el-form-item>
              <template v-if="formData.SMSAuthenticationType === '账号密码'">
                <el-form-item label="发送账号" prop="AppID">
                  <el-input
                    v-model="formData.AppID"
                    placeholder="请输入发送账号"
                    class="itemWidth"
                  ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="AppSecret">
                  <el-input
                    v-model="formData.AppSecret"
                    placeholder="请输入密码"
                    type="password"
                    class="itemWidth"
                  ></el-input>
                </el-form-item>
              </template>
              <template v-if="formData.SMSAuthenticationType === 'APIKEY'">
                <el-form-item label="APIKEY" prop="APIKEY">
                  <el-input
                    v-model="formData.APIKEY"
                    placeholder="请输入APIKEY"
                    class="itemWidth"
                  ></el-input>
                </el-form-item>
              </template>
            </template>
            <el-form-item label="短信内容样式">
              <radio-item
                v-for="(item, index) in templateType"
                :key="index"
                :detail="item"
                :active="formData.IsTemplateSMS === item.value"
                :disabled="disabled || saveType === 'edit'"
                @change="handleTemplateType"
              ></radio-item>
            </el-form-item>
            <el-form-item
              label="触达限制" prop="Condition"
              :label-width="LabelWidth"
            >
              <el-row :gutter="10">
                <el-col :span="2">
                  <el-switch
                    v-model="formData.Condition.IsActive"
                    active-value="1"
                    inactive-value="0"
                    :disabled="disabled"
                  > </el-switch>
                </el-col>
                <template v-if="formData.Condition.IsActive === '1'">
                  <el-col :span="2">
                    <span>每个用户</span>
                  </el-col>
                  <el-col :span="4">
                    <el-input-number
                      v-model="formData.Condition.TimeValue"
                      style="width: 100%"
                      :min="1"
                      :max="
                        formData.Condition.TimeUnit == '小时'
                          ? 24
                          : formData.Condition.TimeUnit == '天'
                          ? 7
                          : formData.Condition.TimeUnit == '周'
                          ? 4
                          : 3
                      "
                      :disabled="disabled"
                    ></el-input-number>
                  </el-col>
                  <el-col :span="4">
                    <el-select
                      v-model="formData.Condition.TimeUnit"
                      style="width: 100%"
                      :disabled="disabled"
                    >
                      <!-- <el-option label="分" value="分"></el-option> -->
                      <el-option label="小时" value="小时"></el-option>
                      <el-option label="天" value="天"></el-option>
                      <el-option label="周" value="周"></el-option>
                      <el-option label="月" value="月"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="3">
                    <span>内，最多接收</span>
                  </el-col>
                  <el-col :span="4">
                    <el-input-number
                      v-model="formData.Condition.LimitMessageNumber"
                      style="width: 100%"
                      :min="1"
                      :max="1000"
                      :disabled="disabled"
                    ></el-input-number>
                  </el-col>
                  <el-col :span="3">
                    <span>条信息推送</span>
                  </el-col>
                </template>
              </el-row>
            </el-form-item>

            <el-form-item label="是否启用" prop="IsActive">
              <el-switch v-model="formData.IsActive"> </el-switch>
            </el-form-item>
          </cond-card>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import radioItem from "@/components/radio-item";
import { mapGetters } from "vuex";
import {
  apiAddChannel,
  apiUpdateChannel,
  apiGetChannelDetail,
} from "@/api/channel";
import condCard from "@/components/cond-card";

export default {
  components: {
    condCard,
    radioItem,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      saving: false,
      direction: "rtl",
      LabelWidth: "130px",
      typeList: ["创蓝云智", "大汉", "梦网云", "永达自定义"],
      SMSAuthenticationTypeList: ["账号密码", "APIKEY"],
      formData: {
        SMSChannelType: "",
        Name: "",
        Type: "短信",
        ServiceURL: "",
        AppID: "",
        AppSecret: "",
        IsActive: true,
        SMSAuthenticationType: "账号密码",
        APIKEY: "",
        IsTemplateSMS: false,
        Condition: {
          IsActive: '0',
          TimeValue: 1,
          TimeUnit: '月',
          LimitMessageNumber: 1
        }
      },
      formRule: {
        SMSChannelType: {
          required: true,
          message: "请选择短信平台",
          trigger: "change",
        },
        Name: {
          required: true,
          message: "请输入账号名称",
          trigger: "blur",
        },
        ServiceURL: {
          required: true,
          message: "请输入请求地址",
          trigger: "blur",
        },
        SMSAuthenticationType: {
          required: true,
          message: "请选择鉴权规则",
          trigger: "change",
        },
        AppID: {
          required: true,
          message: "请输入发送账号",
          trigger: "blur",
        },
        AppSecret: {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
        APIKEY: {
          required: true,
          message: "请输入APIKEY",
          trigger: "blur",
        },
      },
      disabled: false,
      templateType: [
        {
          icon: "iconfont icon-modelId",
          name: "模板ID/内容变量",
          desc: "必须通过模板ID来编辑短信内容",
          value: true,
        },
        {
          icon: "iconfont icon-message",
          name: "短信内容",
          desc: "可以直接编辑短信内容",
          value: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      menuPermission: "common/menuPermission",
    }),
  },
  watch: {
    // exist(newVal) {
    //   if (newVal === true) {
    //     this.getChannelDetail();
    //   } else {
    //     this.resetForm();
    //   }
    // },

    "formData.Condition.TimeUnit": {
      
      handler(value) {
        if (value === '小时') {
          if (this.formData.Condition.TimeValue > 24) {
            this.formData.Condition.TimeValue = 24
          }
          return
        }
        if (value === '天') {
          if (this.formData.Condition.TimeValue > 7) {
            this.formData.Condition.TimeValue = 7
          }
          return
        }
        if (value === '周') {
          if (this.formData.Condition.TimeValue > 4) {
            this.formData.Condition.TimeValue = 4
          }
          return
        }
        if (value === '月') {
          if (this.formData.Condition.TimeValue > 3) {
            this.formData.Condition.TimeValue = 3
          }
          return
        }
      },
      deep: true
    }
  },
  methods: {
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    handleTemplateType(item) {
      this.formData.IsTemplateSMS = item.value;
    },
    selectChange(item) {
      this.formData = {
        Name: "",
        Type: "短信",
        SMSChannelType: item,
        ServiceURL: "",
        AppID: "",
        AppSecret: "",
        IsActive: true,
        SMSAuthenticationType: "账号密码",
        APIKEY: "",
        IsTemplateSMS: item === "大汉" ? true : false,
        Condition: {
          IsActive: '0',
          TimeValue: 1,
          TimeUnit: '月',
          LimitMessageNumber: 1
        }
      };
    },
    submitForm() {
      this.saving = true;
      const param = {
        ...this.formData,
      };
      param.IsActive = param.IsActive ? 1 : 0;
      param.IsTemplateSMS = param.IsTemplateSMS ? 1 : 0;
      if (param.SMSChannelType === "梦网云") {
        if (param.SMSAuthenticationType === "账号密码") {
          delete param.APIKEY;
        } else {
          delete param.AppID;
          delete param.AppSecret;
        }
      } else {
        delete param.APIKEY;
        // delete param.SMSAuthenticationType;
      }
      let prom;
      if (this.saveType === "add") {
        prom = apiAddChannel(param);
      } else {
        prom = apiUpdateChannel(this.detail._id, param);
      }
      prom
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
          console.log(res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    resetForm() {
      this.formData = {
        Name: "",
        Type: "短信",
        SMSChannelType: "",
        ServiceURL: "",
        AppID: "",
        AppSecret: "",
        IsActive: true,
        SMSAuthenticationType: "",
        APIKEY: "",
        IsTemplateSMS: false,
        Condition: {
          IsActive: '0',
          TimeValue: 1,
          TimeUnit: '月',
          LimitMessageNumber: 1
        }
      };
    },
    getChannelDetail() {
      if (this.saveType === "edit" || this.saveType === "show") {
        if (this.saveType === "show") {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
        apiGetChannelDetail(this.detail._id).then((res) => {
          Object.assign(this.formData, {
            Name: res.Name,
            SMSChannelType: res.SMSChannelType,
            ServiceURL: res.ServiceURL,
            AppID: res.AppID,
            AppSecret: res.AppSecret,
            IsActive: res.IsActive == 1 ? true : false,
            SMSAuthenticationType: res.SMSAuthenticationType,
            APIKEY: res.APIKEY,
            IsTemplateSMS: res.IsTemplateSMS == 1 ? true : false,
            Condition: {
              IsActive: res.Condition && res.Condition.IsActive ? res.Condition.IsActive : '0',
              TimeValue: res.Condition && res.Condition.TimeValue ? res.Condition.TimeValue : 1,
              TimeUnit: res.Condition && res.Condition.TimeUnit ? res.Condition.TimeUnit : '月',
              LimitMessageNumber: res.Condition && res.Condition.LimitMessageNumber ? res.Condition.LimitMessageNumber : 1
            }
          });
        });
      }
    },
  },
  created() {
    if (this.detail) {
      this.getChannelDetail();
    }
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/.el-input-number--small .el-input-number__decrease,
  /deep/ .el-input-number--small .el-input-number__increase {
    line-height: 34px;
  }
  /deep/ .el-drawer {
    width: 72% !important;
    background: #f6f7f8;

    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }

    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }

    .el-form-item {
      margin-bottom: 18px;
    }
    .itemWidth {
      width: 440px;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);

    .left {
      font-size: 24px;
      line-height: 32px;

      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }

  .cond-combination {
    .title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }

    .content {
      padding: 20px;
      padding-bottom: 6px;
      background: #f6f7f8;
      border-radius: 6px;
    }
  }

  .plan-types {
    overflow: hidden;
    margin-bottom: -16px;
  }
}
</style>
