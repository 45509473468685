<template>
  <div class="page-customer">
    <content-header :title="title" :breadList="['素材和渠道集成', '短信']">
      <el-button
        slot="tools"
        type="primary"
        round
        @click="
          existDrawer = true;
          editType = 'add';
        "
        v-if="
          menuPermission &&
          menuPermission.渠道管理 &&
          menuPermission.渠道管理.create
        "
      >
        <i class="el-icon-plus"></i>
        <span>新增短信平台</span>
      </el-button>
    </content-header>
    <div class="content-wrapper">
      <div class="summary">
        <div class="item" v-for="item in summary" :key="item.label">
          <div class="label" :style="{ background: item.bgcolor }">
            {{ item.label }}
          </div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>
      <!-- 筛选条件 -->
      <div style="text-align: right" v-if="!showQuery">
        <img
          @click="showQuery = !showQuery"
          class="showQuery"
          src="@/assets/img/query-more.png"
          alt=""
        />
      </div>
      <div class="query-form" v-if="showQuery">
        <i class="el-icon-close" @click="showQuery = false"></i>
        <el-form :inline="true" v-if="showQuery" label-width="100px">
          <el-form-item label="名称：">
            <el-input v-model="query.Name" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item label="状态：">
            <el-select v-model="query.IsActive" placeholder="状态" clearable>
              <el-option :value="1" label="启用"></el-option>
              <el-option :value="0" label="停用"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建者：">
            <el-select v-model="query.CreatedById" placeholder="创建者" clearable>
              <el-option
                :value="item._id"
                :label="item.Name"
                v-for="item in userList"
                :key="item._id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-button size="medium" type="info" @click="handleQuery" round
            >应用</el-button
          >
        </el-form>
      </div>

      <el-table :data="tableData" v-loading="loading" class="table-customer">
        <el-table-column prop="Name" label="名称"> </el-table-column>
        <el-table-column prop="SMSChannelType" label="短信平台">
        </el-table-column>
        <el-table-column prop="Status" label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.IsActive"
              @change="updateChannel(scope.row, $event)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="CreatedByUser" label="创建者"> </el-table-column>
        <el-table-column prop="CreatedDate" label="创建时间"> </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <div class="tool-wrap">
              <el-button type="text" @click="handleEdit(scope.row)">
                <i class="iconfont icon-edit"></i>
              </el-button>
              <el-dropdown @command="handleCommand($event, scope.row)">
                <span class="el-dropdown-link">
                  <i class="iconfont icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="delete"
                    ><i class="iconfont icon-delete"></i>删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        layout="prev, pager, next"
        :total="pagination.totalCount"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        @current-change="handlePage"
      >
      </el-pagination>
    </div>
    <edit-drawer
      :exist.sync="existDrawer"
      :saveType="editType"
      :detail="curPlan"
      v-if="existDrawer"
      @success="handleSaved"
    ></edit-drawer>
    <el-dialog title="删除确认" :visible.sync="delConfirmShow" width="30%">
      <span>确定删除该短信平台吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delConfirmShow = false" type="text">取 消</el-button>
        <el-button type="primary" @click="handleDelete" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentHeader from "@/views/layout/content-header";
import EditDrawer from "./components/edit-drawer";
import {
  apiGetChannelListByType,
  apiDeleteChannel,
  apiUpdateChannel,
} from "@/api/channel";
import { apiGetMessageChannelPermission, apiGetUserList } from "@/api/auth";
export default {
  components: {
    EditDrawer,
    ContentHeader,
  },
  data() {
    return {
      title: this.$route.meta.title,
      loading: false,
      summary: [],
      tableData: [],
      existDrawer: false,
      delConfirmShow: false,
      editType: "add",
      curPlan: {},
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
      query: {
        Name: "",
        IsActive: "",
        CreatedById: "",
      },
      showQuery: false,
      userList: [],
    };
  },
  computed: {
    ...mapGetters({
      menuPermission: "common/menuPermission",
    }),
  },
  methods: {
    getChannelList() {
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
        ...this.query,
      };
      apiGetChannelListByType("短信", param)
        .then((res) => {
          const list = res.channels;
          list.forEach((item) => {
            item.IsActive = +item.IsActive == 1 ? true : false;
          });
          this.tableData = list;
          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleEdit(detail) {
      // 请求编辑权限
      apiGetMessageChannelPermission(detail._id, {
        target: "edit",
      }).then((res) => {
        if (res.PermissionEdit) {
          this.curPlan = detail;
          this.editType = "edit";
          this.existDrawer = true;
        } else {
          this.curPlan = detail;
          this.editType = "show";
          this.existDrawer = true;
          //this.$message.error("无编辑权限");
        }
      });
    },
    handleCommand(cmd, detail) {
      switch (cmd) {
        case "detail":
          this.$router.push(`/group/detail/${detail._id}`);
          break;
        case "delete":
          this.curPlan = detail;
          this.delConfirmShow = true;
          break;
      }
    },
    handleSaved() {
      this.existDrawer = false;
      this.getChannelList();
    },
    handleDelete() {
      apiGetMessageChannelPermission(this.curPlan._id, {
        target: "delete",
      }).then((res) => {
        if (res.PermissionDelete) {
          apiDeleteChannel(this.curPlan._id).then(() => {
            this.delConfirmShow = false;
            this.getChannelList();
          });
        } else {
          this.$message.error("无删除权限");
        }
      });
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getChannelList();
    },

    handleQuery() {
      this.handlePage(1);
    },

    updateChannel(channel, val) {
      apiGetMessageChannelPermission(channel._id, {
        target: "edit",
      }).then((res) => {
        if (res.PermissionEdit) {
          apiUpdateChannel(channel._id, {
            IsActive: val === true ? 1 : 0,
          })
            .then(() => {
              this.$message({
                message: "更新成功",
                type: "success",
              });
            })
            .catch(() => {
              channel.IsActive = !channel.IsActive;
            });
        } else {
          channel.IsActive = !channel.IsActive;
          this.$message.error("无编辑权限");
        }
      });
    },
  },
  mounted() {
    this.getChannelList();
    const query = this.$route.query;
    apiGetUserList({
      limit: 99999,
    }).then((res) => {
      this.userList = res.users;
    });

    if (query.action) {
      this.curPlan = {
        _id: query.id,
      };
      this.editType = query.action;
      this.existDrawer = true;
    }
  },
};
</script>

<style lang="less" scoped>
.page-customer {
  .tool-wrap {
    /deep/ i {
      font-size: 20px;
      margin-right: 20px;
      color: #9a9a9e;
    }
  }
  .summary {
    display: flex;
    .item {
      margin-right: 76px;
      text-align: center;
    }
    .label {
      height: 22px;
      line-height: 22px;
      font-size: 12px;
      margin-bottom: 4px;
      padding: 0 7px;
      background: #d7dcdf;
      border-radius: 20px;
    }
    .value {
      font-family: Noto Sans Display;
      font-size: 48px;
      font-weight: 300;
      line-height: 56px;
      color: #747378;
    }
  }
  .query-form {
    background: #f6f7f8;
    border-radius: 6px;
    position: relative;
    padding: 30px 90px 6px 16px;
    margin: 0 0 20px 0;
    .el-icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    /deep/ .el-button--info {
      position: absolute;
      right: 10px;
      bottom: 16px;
      background: #4d4b58;
    }
  }
}
</style>
