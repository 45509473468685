<template>
  <div class="keysBox">
    <!-- <div class="labelBox">
      <div class="text">自定义跳转</div>
      <el-button type="primary" @click="add" size="mini" class="btn"
        >添加key</el-button
      >
    </div> -->

    <!-- <div class="header">
      <div>key</div>
      <div>提醒文案</div>
      <div>操作</div>
    </div> -->
    <div class="content">
      <div class="contentItem" v-for="(item, index) in list" :key="item.index">
        <div class="index">{{ index + 1 }}</div>
        <div>
          <el-input placeholder="key" v-model="item.key" size="mini" />
        </div>
        <div>
          <el-input placeholder="提醒文案" v-model="item.value" size="mini" />
        </div>
        <div class="delete" @click="del(item.index)">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </div>
    <div style="margin-left: 44px">
      <el-button type="text" @click="add">+ 添加key</el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    defaultValue: null,
  },

  data() {
    return {
      list: [],
    };
  },

  watch: {
    defaultValue(newVal) {
      if (newVal) {
        try {
          const list = [];
          Object.keys(newVal).forEach((key) => {
            list.push({
              index: Math.floor(Math.random() * 1000000),
              key: key,
              value: newVal[key],
            });
          });
          this.list = list;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  methods: {
    del(index) {
      this.list = this.list.filter((item) => {
        return item.index !== index;
      });
    },
    add() {
      this.list.push({
        index: Math.floor(Math.random() * 1000000),
        key: "",
        value: "",
      });
    },
    // 外部调用
    getData() {
      const data = {};
      this.list.forEach((item) => {
        if (item.key) {
          data[item.key] = item.value;
        }
      });
      return data;
    },
  },
};
</script>

<style lang="less" scoped>
.keysBox {
  width: 510px;
  font-size: 12px;
  padding: 20px 16px;
  border: 1px solid #dddddf;
  border-radius: 6px;

  .labelBox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .text {
      width: 150px;
    }
    .btn {
      margin-left: auto;
    }
  }
  .header {
    display: flex;
    align-items: center;
    height: 48px;

    & > div {
      color: #909399;
      font-weight: 500;
      background: rgba(246, 247, 248, 0.5);
      border-bottom: 1px solid #ebeef5;
      line-height: 48px;
      padding-left: 10px;
      padding-right: 10px;
    }

    & div:nth-child(1) {
      flex: 1;
    }
    & div:nth-child(2) {
      flex: 2;
    }
    & div:nth-child(3) {
      text-align: center;
      width: 120px;
    }
  }

  .content {
    .contentItem {
      display: flex;
      // border-bottom: 1px solid #ebeef5;
      align-items: center;
      margin-bottom: 14px;
      .index {
        width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 12px;
        background: #f6f7f8;
        border-radius: 50%;
        text-align: center;
        margin-right: 17px;
      }
      // & > div {
      //   // padding: 12px 0;
      //   padding-right: 10px;
      // }

      & > div:nth-child(2) {
        flex: 1;
        margin-right: 13px;
      }
      & > div:nth-child(3) {
        flex: 2;
      }

      .delete {
        width: 60px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
</style>
