<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :wrapperClosable="false"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{
            saveType === "add"
              ? "新增企业微信"
              : saveType === "show"
              ? "查看企业微信"
              : "修改企业微信"
          }}</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            round
            @click="handleSave"
            :loading="saving"
            :disabled="disabled"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          label-width="80px"
          :disabled="disabled"
        >
          <cond-card>
            <el-form-item
              :label-width="LabelWidth"
              label="应用名称"
              prop="Name"
            >
              <el-input
                v-model="formData.Name"
                placeholder="请输入企业微信名称-应用名称"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label-width="LabelWidth"
              label="自定义获取token接口"
              prop="CustomizedTokenAPI"
            >
              <el-switch v-model="formData.CustomizedTokenAPI"></el-switch>
            </el-form-item>
            <el-form-item :label-width="LabelWidth" label="企业ID" prop="AppID">
              <el-input
                :disabled="saveType !== 'add'"
                v-model="formData.AppID"
                placeholder="请输入企业ID"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label-width="LabelWidth"
              label="应用类型"
              prop="EnterpriseWechatAppType"
            >
              <el-radio-group
                v-model="formData.EnterpriseWechatAppType"
                :disabled="saveType !== 'add'"
              >
                <el-radio
                  :label="item"
                  :key="item"
                  v-for="item in EnterpriseWechatAppTypeList"
                  >{{ item }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
              v-if="formData.EnterpriseWechatAppType === '自建应用'"
              :label-width="LabelWidth"
              :label="
                formData.CustomizedTokenAPI ? '渠道ID(AgentId)' : 'AgentId'
              "
            >
              <el-input
                :disabled="saveType !== 'add'"
                v-model="formData.AgentId"
                :placeholder="
                  formData.CustomizedTokenAPI
                    ? '请输入渠道ID(AgentId)'
                    : '请输入AgentId'
                "
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="!formData.CustomizedTokenAPI"
              :label-width="LabelWidth"
              :label="channelLabelMap.AppSecret"
              prop="AppSecret"
            >
              <el-input
                v-model="formData.AppSecret"
                placeholder="请输入密码"
                type="password"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <div v-if="formData.CustomizedTokenAPI">
              <el-form-item
                :label-width="LabelWidth"
                label="token获取接口"
                prop="CustomizedTokenAPIURL"
              >
                <el-input
                  v-model="formData.CustomizedTokenAPIURL"
                  placeholder="请输入获取token接口"
                  style="width: 440px"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label-width="LabelWidth"
                label="用户名"
                prop="UserName"
              >
                <el-input
                  v-model="formData.UserName"
                  placeholder="请输入用户名"
                  style="width: 440px"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label-width="LabelWidth"
                label="公钥"
                prop="PublicKey"
              >
                <el-input
                  v-model="formData.PublicKey"
                  type="password"
                  placeholder="请输入公钥"
                  style="width: 440px"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item
              :label-width="LabelWidth"
              :label="'所属组织'"
              prop="OrgName"
            >
              <el-select
                v-model="formData.OrgName"
                multiple
                filterable
                placeholder="请选择所属组织"
                style="width: 440px"
              >
                <el-option
                  v-for="item in OrganizeList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="触达限制" prop="Condition"
              :label-width="LabelWidth"
            >
              <el-row :gutter="10">
                <el-col :span="2">
                  <el-switch
                    v-model="formData.Condition.IsActive"
                    active-value="1"
                    inactive-value="0"
                    :disabled="disabled"
                  > </el-switch>
                </el-col>
                <template v-if="formData.Condition.IsActive === '1'">
                  <el-col :span="2">
                    <span>每个用户</span>
                  </el-col>
                  <el-col :span="4">
                    <el-input-number
                      v-model="formData.Condition.TimeValue"
                      style="width: 100%"
                      :min="1"
                      :max="
                        formData.Condition.TimeUnit == '小时'
                          ? 24
                          : formData.Condition.TimeUnit == '天'
                          ? 7
                          : formData.Condition.TimeUnit == '周'
                          ? 4
                          : 3
                      "
                      :disabled="disabled"
                    ></el-input-number>
                  </el-col>
                  <el-col :span="4">
                    <el-select
                      v-model="formData.Condition.TimeUnit"
                      style="width: 100%"
                      :disabled="disabled"
                    >
                      <!-- <el-option label="分" value="分"></el-option> -->
                      <el-option label="小时" value="小时"></el-option>
                      <el-option label="天" value="天"></el-option>
                      <el-option label="周" value="周"></el-option>
                      <el-option label="月" value="月"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="3">
                    <span>内，最多接收</span>
                  </el-col>
                  <el-col :span="4">
                    <el-input-number
                      v-model="formData.Condition.LimitMessageNumber"
                      style="width: 100%"
                      :min="1"
                      :max="1000"
                      :disabled="disabled"
                    ></el-input-number>
                  </el-col>
                  <el-col :span="3">
                    <span>条信息推送</span>
                  </el-col>
                </template>
              </el-row>
            </el-form-item>

            <el-form-item
              :label-width="LabelWidth"
              label="是否启用"
              prop="IsActive"
            >
              <el-switch v-model="formData.IsActive"> </el-switch>
            </el-form-item>
          </cond-card>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  apiAddChannel,
  apiUpdateChannel,
  apiGetChannelDetail,
} from "@/api/channel";
import condCard from "@/components/cond-card";
export default {
  components: {
    condCard,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
  },
  data() {
    return {
      saving: false,
      direction: "rtl",
      LabelWidth: "130px",
      formData: {
        Name: "",
        Type: "企业微信",
        AgentId: "",
        AppID: "",
        AppSecret: "",
        IsActive: true,
        CustomizedTokenAPI: false,
        OrgName: [],
        CustomizedTokenAPIURL: "",
        PublicKey: "",
        UserName: "",
        EnterpriseWechatAppType: "客户联系",
        Condition: {
          IsActive: '0',
          TimeValue: 1,
          TimeUnit: '月',
          LimitMessageNumber: 1
        }
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入企业微信名称-应用名称",
          trigger: "blur",
        },
        AppID: {
          required: true,
          message: "请输入企业ID",
          trigger: "blur",
        },
        AppSecret: {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
        ChannelID: {
          required: true,
          message: "请输入渠道ID(AppID)",
          trigger: "blur",
        },
        CustomizedTokenAPIURL: {
          required: true,
          message: "请输入获取token接口",
          trigger: "blur",
        },
        OrgName: {
          required: false,
          message: "请选择所属组织",
          trigger: "blur",
        },
        UserName: {
          required: true,
          message: "请输入用户名",
          trigger: "blur",
        },
        PublicKey: {
          required: true,
          message: "请输入公钥",
          trigger: "blur",
        },
        EnterpriseWechatAppType: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
      },
      disabled: false,
    };
  },
  computed: {
    ...mapGetters({
      channelFields: "common/channelFields",
      channelLabelMap: "common/channelLabelMap",
    }),
    ...mapGetters({
      menuPermission: "common/menuPermission",
    }),
    OrganizeList() {
      const channel =
        this.channelFields.find((item) => item.APIName === "OrgName") || {};
      return channel.PicklistOptions;
    },
    EnterpriseWechatAppTypeList() {
      const channel =
        this.channelFields.find(
          (item) => item.APIName === "EnterpriseWechatAppType"
        ) || {};
      return channel.PicklistOptions;
    },
  },
  watch: {
    exist(newVal) {
      if (newVal === true) {
        this.getChannelDetail();
      } else {
        this.resetForm();
      }
    },
    "formData.Condition.TimeUnit": {
      
      handler(value) {
        if (value === '小时') {
          if (this.formData.Condition.TimeValue > 24) {
            this.formData.Condition.TimeValue = 24
          }
          return
        }
        if (value === '天') {
          if (this.formData.Condition.TimeValue > 7) {
            this.formData.Condition.TimeValue = 7
          }
          return
        }
        if (value === '周') {
          if (this.formData.Condition.TimeValue > 4) {
            this.formData.Condition.TimeValue = 4
          }
          return
        }
        if (value === '月') {
          if (this.formData.Condition.TimeValue > 3) {
            this.formData.Condition.TimeValue = 3
          }
          return
        }
      },
      deep: true
    }
  },
  methods: {
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      this.saving = true;
      const param = {
        ...this.formData,
      };
      param.IsActive = param.IsActive ? 1 : 0;
      param.CustomizedTokenAPI = param.CustomizedTokenAPI ? 1 : 0;
      let prom;
      if (this.saveType === "add") {
        prom = apiAddChannel(param);
      } else {
        prom = apiUpdateChannel(this.detail._id, param);
      }
      prom
        .then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$emit("success", res);
          console.log(res);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    resetForm() {
      this.formData = {
        Name: "",
        Type: "企业微信",
        AppID: "",
        AppSecret: "",
        IsActive: true,
        AgentId: "",
        CustomizedTokenAPIURL: "",
        CustomizedTokenAPI: false,
        UserName: "",
        PublicKey: "",
        OrgName: [],
        EnterpriseWechatAppType: "客户联系",
        Condition: {
          IsActive: '0',
          TimeValue: 1,
          TimeUnit: '月',
          LimitMessageNumber: 1
        }
      };
    },
    getChannelDetail() {
      if (this.saveType === "edit" || this.saveType === "show") {
        if (this.saveType === "show") {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
        apiGetChannelDetail(this.detail._id).then((res) => {
          Object.assign(this.formData, {
            Name: res.Name,
            AppID: res.AppID,
            AppSecret: res.AppSecret,
            AgentId: res.AgentId,
            IsActive: res.IsActive == 1 ? true : false,
            CustomizedTokenAPI:
              res.CustomizedTokenAPI == "1" || res.CustomizedTokenAPI == 1
                ? true
                : false,
            OrgName: Array.isArray(res.OrgName) ? res.OrgName : [res.OrgName],
            CustomizedTokenAPIURL: res.CustomizedTokenAPIURL,
            UserName: res.UserName,
            PublicKey: res.PublicKey,
            EnterpriseWechatAppType: res.EnterpriseWechatAppType,
            Condition: {
              IsActive: res.Condition && res.Condition.IsActive ? res.Condition.IsActive : '0',
              TimeValue: res.Condition && res.Condition.TimeValue ? res.Condition.TimeValue : 1,
              TimeUnit: res.Condition && res.Condition.TimeUnit ? res.Condition.TimeUnit : '月',
              LimitMessageNumber: res.Condition && res.Condition.LimitMessageNumber ? res.Condition.LimitMessageNumber : 1
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/.el-input-number--small .el-input-number__decrease,
  /deep/ .el-input-number--small .el-input-number__increase {
    line-height: 34px;
  }
  /deep/ .el-drawer {
    width: 72% !important;
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }

  .cond-combination {
    .title {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
    }
    .content {
      padding: 20px;
      padding-bottom: 6px;
      background: #f6f7f8;
      border-radius: 6px;
    }
  }
  .plan-types {
    overflow: hidden;
    margin-bottom: -16px;
  }
}
</style>
